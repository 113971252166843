@import 'src/styles/variables';

.container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-desktop) {
        border-bottom: 0;
    }
}

.input_block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.penalty_block {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.input {
    flex-grow: 1;
}

.divider {
    margin-top: 24px;

    @media screen and (min-width: $breakpoint-desktop) {
        display: none;
    }
}
