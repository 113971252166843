@import 'src/styles/variables';

.row {
    display: flex;
    justify-content: space-between;
}

.close_icon {
    cursor: pointer;
    margin-right: 5px;
    &:hover {
        opacity: 0.8;
    }
}

.term_control {
    width: 100%;
    margin-top: 16px;

    @media screen and (min-width: $breakpoint-desktop) {
        margin-bottom: 0;
    }
}

.plusIcon {
    padding-right: 4px;
}

.divider {
    margin-top: 16px;

    @media screen and (min-width: $breakpoint-desktop) {
        display: none;
    }
}

.inputWrapper {
    width: 100%;
}
