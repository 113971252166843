@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (min-width: $breakpoint-desktop) {
        flex-direction: row;
    }

    .phoneInput {
        @media screen and (min-width: $breakpoint-desktop) {
            flex: 3;
        }
    }

    .button {
        width: 100%;

        @media screen and (min-width: $breakpoint-desktop) {
            flex: 1;
        }
    }
}

.errorMessage {
    color: var(--light-accent-red-100, var(--red-red-100, #c11d1d));
}
