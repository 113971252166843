@import 'src/styles/variables';

.container {
    padding: 30px;
    background-color: var(--color-D10);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.row {
    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        &:nth-child(3) {
            margin-top: auto;
        }
    }
}

.legend {
    display: flex;
}

.legend_item {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 15px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-D60);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 8px;
        height: 8px;
        background-color: var(--color);
        border-radius: 50%;
        transform: translateY(-50%);
    }
}

.legend_bar {
    display: flex;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    margin: 16px 0;
}

.legend_bar_item {
    height: 100%;
    width: var(--width);
    background-color: var(--color);
}

.calculation_block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calculation_item {
    display: flex;
    justify-content: space-between;
}

.calculation_item_name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-D60);
}

.calculation_item_value {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
}

.empty_block {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-D60);
    display: flex;
    flex-direction: row;
}

.button {
    font-weight: 600;
    text-transform: none;
    font-size: 16px;
    margin-top: 20px;
}
