@import 'src/styles/variables';

.container {
    padding: 30px;
    background-color: var(--color-L100);

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    border-radius: 20px;

    @media screen and (min-width: $breakpoint-desktop) {
        min-height: 355px;
        flex-direction: row;
    }

    @media screen and (max-width: 1023px) {
        padding: 16px;
        gap: 16px;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.columnsWrapper {
    display: flex;
    width: 100%;
    gap: 30px;

    @media screen and (max-width: $breakpoint-desktop) {
        min-height: 355px;
        flex-direction: column;
    }
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
        width: 50%;
    }
}
