@import 'src/styles/variables';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    gap: 16px;
    background-color: var(--color-L100);
    border-radius: 12px;

    @media screen and (min-width: $breakpoint-desktop) {
        border-radius: 16px;
        gap: 12px;
    }
}

.footerText {
    color: var(--light-theme-dark-60, var(--main-dark-60, #636f7d));
}
