@import 'src/styles/mixins';

.layout {
    @include max-content-width;
    padding: 0;

    padding-left: 16px;
    padding-right: 16px;

    @media screen and (min-width: $breakpoint-desktop) {
        padding-left: 30px;
        padding-right: 30px;
    }
}
