@import 'src/styles/variables';

.product_list {
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-bottom: 24px;
  }
}

.page_header {
  margin-top: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-bottom: 32px;
  }
}
